import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { breakpoints } from '../../style-utilities/variables';
import ComponentStyles from '../../brand-styles/components/Footer/UtilityLink.styles';

const StyledUtilityLink = styled.ul`
  text-align: center;
  grid-area: UtilityLink;
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    text-decoration: none;
    display: inline-block;
    margin: 5px 15px;
  }

  /* Dynamic Brand Styles */
  ${ComponentStyles}

  @media (max-width: ${breakpoints.tablet}) {}
`;

const UtilityLink = ({ siteTitle }) => {
  return (
      <StyledUtilityLink>
        <li>
          <Link to="/faq-categories">FAQs</Link>
        </li>
        <li>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/cookie">Cookie Policy</Link>
        </li>
        <li>
          <Link to="/terms-of-use">Terms of Use</Link>
        </li>
        <li>
          <Link to="/contact">Contact Us</Link>
        </li>
        <li>
            <Link to="https://winlandfoods.wd1.myworkdayjobs.com/External">Careers</Link>
        </li>
        <li>
          <Link to="/california-supply-chain-transparency-law">California Supply Chain Transparency Law</Link>
        </li>
        <li>
          <button id="ot-sdk-btn" class="ot-sdk-show-settings">Do Not Sell My Personal Information</button>
        </li>
      </StyledUtilityLink>
  );
};

export default UtilityLink;
